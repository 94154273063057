<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >设置刊中画面来源</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
      <DatePicker
        placeholder="选择作业日期"
        size="small"
        v-model="actionDate"
        type="date"
        style="width:100%"
        :editable="false"
        :clearable="false"
        @on-change="changeActionDate"
      ></DatePicker>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">选择刊中画面</h4>
      <Row :gutter="8">
        <i-col span="19">
          <Input
            v-model="query.keyword"
            size="small"
            placeholder="资源编号"
          />
        </i-col>
        <i-col span="3">
          <Button
            size="small"
            icon="ios-search"
            type="primary"
            class="m-r-5"
            @click="handleSearch"
          >搜索</Button>
        </i-col>
      </Row>

    </div>
    <div class="m-b-10">
      <Table
        stripe
        size="small"
        :data="tableList"
        :columns="tableColumns"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          @on-change="changePage"
          :current="query.pageNumber"
        ></Page>
      </div>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">备注</h4>
      <Input
        type="textarea"
        size="small"
        :rows="3"
        v-model.trim="remarks"
        placeholder="备注"
      />
    </div>
    <div class="text-right">
      <Button
        type="success"
        size="small"
        :disabled="!actionDate||!pictureTaskId"
        @click="handleSubmit"
      >确认配置</Button>
    </div>
  </div>
</template>

<script>
import { getPublishingTaskitemPage } from '@/api/msp/taskitem'
import { setPublishingPicSource, updatePublishingPicSource } from '@/api/msp/demandV3'
export default {
  data () {
    return {
      query: {
        resourceId: null,
        deviceId: null,
        taskId: null,
        keyword: '',
        pageSize: 15,
        pageNumber: 1,
        publishing: 2
      },
      actionDate: '',
      pictureTaskId: null,
      remarks: '',
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '所属站台', align: 'center', key: 'stationName' },
        { title: '资源编号', align: 'center', key: 'resourceCode' },
        { title: '资源类型', align: 'center', key: 'resourcetypeName' },
        {
          title: '上刊画面',
          align: 'center',
          render: (h, params) => {
            return h(
              'img',
              {
                domProps: {
                  src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                },
                style: {
                  width: '64px'
                }
              }
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.pictureTaskId = this.pictureTaskId === params.row.id ? null : params.row.id
                }
              },
              style: { color: this.pictureTaskId === params.row.id ? '#ed4014' : '' }
            }, this.pictureTaskId === params.row.id ? '取消' : '选择')
          }
        }
      ]
    }
  },
  computed: {
    setOperatingType () {
      return this.$store.state.installSetting.setOperatingType
    },
    curTaskId: {
      get () {
        return this.$store.state.installSetting.currentTaskId
      },
      set (val) {
        this.$store.commit('set_current_taskId', val)
      }
    },
    curResourceId: {
      get () {
        return this.$store.state.installSetting.currentResourceId
      },
      set (val) {
        this.$store.commit('set_current_resourceId', val)
      }
    },
    selectedTaskitemIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    }
  },
  methods: {
    initData () {
      this.query.taskId = this.curTaskId
      this.query.deviceId = this.curResourceId
      getPublishingTaskitemPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableList = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.tableList = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    changeActionDate (date) {
      this.actionDate = date
    },
    handleSubmit () {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }
      if (!this.pictureTaskId) {
        this.$Notice.error({ desc: '请选择一个画面来源信息' })
        return false
      }
      const postData = {
        actionDate: this.actionDate,
        taskId: this.curTaskId,
        remarks: this.remarks,
        fromTaskitemId: this.pictureTaskId,
        extras: ''
      }
      if (this.setOperatingType === 1) {
        postData.deviceId = this.curResourceId
        setPublishingPicSource(postData).then(res => {
          if (res && res.errcode === 0) {
            this.handleSubmitSuccess()
          }
        })
      } else {
        postData.taskitemId = this.selectedTaskitemIds[0]
        updatePublishingPicSource(postData).then(res => {
          if (res && res.errcode === 0) {
            this.handleSubmitSuccess()
          }
        })
      }
    },
    /**
     * 处理成功提交后事件
     */
    handleSubmitSuccess () {
      // 更新store中数据
      this.selectedTaskitemIds = []
      this.curResourceId = null
      this.actionDate = ''
      this.tableList = []
      this.total = 0
      this.query.pageNumber = 1
      this.pictureTaskId = null
      this.$store.commit('set_temp_selected_item', null)

      // 调用列表数据获取函数
      if (this.setOperatingType === 1) {
        this.$store.dispatch('getTaskDetailData')
        // 调用右侧数据统计函数
        this.$store.dispatch('getOrderProductArray')
      } else {
        this.$store.dispatch('getTaskitemData')
      }

      this.$Notice.success({ desc: '操作成功' })
    }
  },
  watch: {
    curResourceId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.initData()
        }
      }
    }
  }
}
</script>
